<template>
  <b-card>
    <validation-observer ref="updateProfileForm" #default="{ invalid }">
      <!-- form -->
      <n-form-confirmation
        key="updateProfileForm"
        @submit="submit"
        :form="$refs.updateProfileForm"
        :disabled="invalid"
      >
        <b-row>
          <b-col sm="6">
            <b-form-group :label="$t('field.name')" label-for="name">
              <validation-provider
                #default="{ errors }"
                vid="name"
                :name="$t('field.name')"
                rules="required"
              >
                <b-form-input
                  v-model="data.name"
                  :placeholder="$t('field.name')"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  name="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('field.phoneNumber')"
              label-for="phoneNumber"
            >
              <validation-provider
                #default="{ errors }"
                vid="name"
                :name="$t('field.phone_number')"
                rules=""
              >
                <b-form-input
                  v-model="data.phone"
                  :placeholder="$t('field.phoneNumber')"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  name="phoneNumber"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('field.username')" label-for="username">
              <validation-provider
                #default="{ errors }"
                vid="username"
                :name="$t('field.username')"
                rules="required"
              >
                <b-form-input
                  v-model="data.username"
                  :placeholder="$t('field.username')"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  name="username"
                  :disabled="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6" v-if="data.account">
            <b-form-group
              :label="
                $t('field.givenCredit') +
                (data.account.ccy === 840 ? '($)' : ' (៛)')
              "
              label-for="givenCredit"
            >
              <validation-provider
                #default="{ errors }"
                vid="givenCredit"
                :name="$t('field.givenCredit')"
                rules="required"
              >
                <n-currency-input
                  v-model="data.account.givenCredit"
                  :id="'givenCredit'"
                  :name="'givenCredit'"
                  :placeholder="$t('field.givenCredit')"
                  disabled
                  :class="errors.length > 0 ? 'is-invalid' : null"
                ></n-currency-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6" v-if="data.account">
            <b-form-group
              :label="
                $t('field.avaliableCredit') +
                (data.account.ccy === 840 ? '($)' : ' (៛)')
              "
              label-for="avaliableCredit"
            >
              <validation-provider
                #default="{ errors }"
                vid="avaliableCredit"
                :name="$t('field.avaliableCredit')"
                rules="required"
              >
                <n-currency-input
                  v-model="data.account.avalBalance"
                  :id="'avaliableCredit'"
                  :name="'avaliableCredit'"
                  :placeholder="$t('field.avaliableCredit')"
                  disabled
                  :class="errors.length > 0 ? 'is-invalid' : null"
                ></n-currency-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6" v-if="data.betSetting">
            <b-form-group
              :label="
                $t('field.commission') +
                (data.betSetting.ccy === 840 ? '($)' : ' (៛)')
              "
              label-for="phoneNumber"
            >
              <validation-provider
                #default="{ errors }"
                vid="name"
                :name="$t('field.commission')"
                rules="required"
              >
                <n-currency-input
                  v-model="data.betSetting.commission"
                  :id="'commission'"
                  :name="'commission'"
                  :placeholder="$t('field.commission')"
                  disabled
                  :class="errors.length > 0 ? 'is-invalid' : null"
                ></n-currency-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="text-right">
            <n-button-loading
              type="submit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              :loading="loading"
              :disabled="invalid"
            >
              {{ $t("button.saveChanges") }}
            </n-button-loading>
            <n-button-loading
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :loading="loading"
              class="mt-1"
              @submit="resetForm"
            >
              {{ $t("button.reset") }}
            </n-button-loading>
          </b-col>
        </b-row>
      </n-form-confirmation>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BLink,
  BRow,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import Repository from "@/repositories/RepositoryFactory";
import NSingleImageUploader from "@/components/NSingleImageUploader";
import NCurrencyInput from "@/components/NCurrencyInput";

const ProfileRepository = Repository.get("profile");

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    BImg,
    NButtonLoading,
    NFormConfirmation,
    NSingleImageUploader,
    BFormDatepicker,
    NCurrencyInput,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      data: JSON.parse(localStorage.getItem("userData")),
      loading: false,
    };
  },
  methods: {
    submit() {
      this.$refs.updateProfileForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          ProfileRepository.updateProfile(this.data)
            .then((response) => {
              const user = response.data.data;
              localStorage.setItem("userData", JSON.stringify(user));
              this.$store.commit("profile/UPDATE_PROFILE", user);
            })
            .catch((error) => {
              if (error.response?.data?.message) {
                this.$refs.updateProfileForm.setErrors(
                  error.response?.data?.message
                );
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
    resetForm() {
      this.data = JSON.parse(localStorage.getItem("userData"));

      this.$nextTick(() => {
        this.$refs.updateProfileForm.reset();
      });
    },
  },
};
</script>
